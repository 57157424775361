import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Intro.css';
import banner1 from './assets/banner1.jpg';

const Intro = () => {
   return (
       <Carousel>
           <Carousel.Item className="intro-container">
               <img className="intro-image" src={banner1} alt="First slide" />
               <Carousel.Caption className="intro-content">
                   <h3 className="intro-title">Te invitamos a la Experiencia Smartfield INIA</h3>
                   <p className="intro-text">
                       Te llevamos nuestro SmartField para que visites las tecnologías en un formato 360º. También puedes agendar una visita guiada.
                   </p>
                   <div className="intro-buttons">
                       <Button className="intro-button" variant="success" href="/smartfield">Más Información</Button>
                       <Button className="intro-button" variant="success" href="https://www.smartfieldinia.cl/plataforma/">ShowRoom</Button>
                   </div>
               </Carousel.Caption>
           </Carousel.Item>
           <Carousel.Item className="intro-container">
               <img className="intro-image" src={banner1} alt="First slide" />
               <Carousel.Caption className="intro-content">
                   <p className="intro-text">
                   Te invitamos a visitar el campus virtual de capacitaciones en tecnologías agrícolas, donde ofrecemos cursos especializados diseñados para mejorar las prácticas agrícolas y facilitar la adopción de nuevas tecnologías. Aprovecha la oportunidad de aprender de expertos en el campo y aplica conocimientos prácticos que transformarán su enfoque agrícola. 
Atento a las fechas para regístrate y dé un paso adelante hacia la excelencia en la agricultura.

                   </p>
                   <div className="intro-buttons">
                       <Button className="intro-button" variant="success" href="/campus">Más Información</Button>
                   </div>
               </Carousel.Caption>
           </Carousel.Item>
       </Carousel>
   );
};

export default Intro;
