import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import navimg from "./assets/INIAINDAP.png";
import "./Navbar.css";


const NavigationBar = () => {
  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand className="NavFirstItem" href="/">
        <img className="imgNavBar" src={navimg}></img>
        <span className="Smart-color">SMART</span>
        <span className="Field-color">FIELD</span>
        
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" />
      <Nav className="ml-auto">
        
        <Nav.Link className="navlink-custom" href="/us">
          Nosotros
        </Nav.Link>
        <Nav.Link className="navlink-custom" href="/smartfield">
          Smartfield
        </Nav.Link>

        <NavDropdown
          title="Proyectos Vinculados"
          className="navlink-custom"
          id="collapsible-nav-dropdown"
        >
          <NavDropdown.Item className="navlink-custom" href="/agroconecta">
            AgroConecta
          </NavDropdown.Item>
          <NavDropdown.Item className="navlink-custom" href="/INDAP">
            INDAP
          </NavDropdown.Item>
        </NavDropdown>

        <NavDropdown
          title="Eventos"
          className="navlink-custom"
          id="collapsible-nav-dropdown"
        >
          <NavDropdown.Item className="navlink-custom" href="/events">
            Publicaciones
          </NavDropdown.Item>
          <NavDropdown.Item className="navlink-custom" href="/library">
            Libreria
          </NavDropdown.Item>
        </NavDropdown>

        <Nav.Link className="navlink-custom" href="/campus">
          Campus
        </Nav.Link>
      </Nav>
    </Navbar>
  );
};

export default NavigationBar;

//Componente Barra de Navegacion
