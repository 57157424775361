import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Training.css';
import { Carousel, Card, Container, Row, Col, Tab, Tabs } from 'react-bootstrap';

import ico1 from './assets/icono1.png'
import ico2 from './assets/icono2.png'
import ico3 from './assets/icono3.png'
import ico4 from './assets/icono4.png'

//Importar empresas
import AgroPrecision from '../Home/assets/Enterprise/image3.jpg'
import DropControl from '../Home/assets/Enterprise/image7.jpg'
import Auravant from '../Home/assets/Enterprise/image5.jpg'
import GeoTrace from '../Home/assets/Enterprise/image10.jpg'
import Instacrops from '../Home/assets/Enterprise/image15.jpg'
import Mapsens from '../Home/assets/Enterprise/image16.jpg'
import AquaDetect from '../Home/assets/Enterprise/image1.jpg' //No Image
import BlassTech from '../Home/assets/Enterprise/image5.jpg' //No Image
import lemSystem from '../Home/assets/Enterprise/image5.jpg' //No Image
import OneSoil from '../Home/assets/Enterprise/image21.jpg' 
import Olivos from '../Home/assets/Enterprise/image20.jpg'
import Impac from '../Home/assets/Enterprise/image12.jpg'
import Negume from '../Home/assets/Enterprise/image19.jpg'
import TC from '../Home/assets/Enterprise/image23.jpg'
import INIA from '../Home/assets/Enterprise/image14.jpg'
import Kimenda from '../Home/assets/Enterprise/image5.jpg' //No image
import Soval from '../Home/assets/Enterprise/image22.jpg'
import Agrometeorologia from '../Home/assets/Enterprise/image2.jpg'
import AgroSmart from '../Home/assets/Enterprise/image4.jpg'
import TheEarthSays from '../Home/assets/Enterprise/image8.jpg'
import TrackStack from '../Home/assets/Enterprise/image5.jpg' //No image
import Hispatec from '../Home/assets/Enterprise/image11.jpg'
import MindLabs from '../Home/assets/Enterprise/image17.jpg'
import AgroInventario from '../Home/assets/Enterprise/image5.jpg' //No Image

const TrainingTemactics = () => {
    return (
        <Container className="Training-container">
            <h3 className="Training-title">Temáticas de Capacitación</h3>
            <Row>
                <p className='Training-paragraph'>
                Para que esto ocurra, será clave la generación de recursos humanos asociados a estas tecnologías. Es necesario un escalamiento desde las capacidades agronómicas tradicionales hacia la generación de capacidades agronómicas digitales, que permitan optimizar los tiempos, la eficiencia y efectividad de los manejos agronómicos frente a los cambios climáticos imperantes, para permitir una mejor competitividad en el sector productivo agrícola.
                </p>
            </Row>
            <Row>

                <Col md={3}>
                    <Card className='TematicsTraining-card'>
                        <Card.Img src={ico3} className='TematicsTraining-icoImage' />
                        <Card.Body>
                            <Card.Title className='TematicsTraining-icoTitle'>Big Data</Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
                  
                <Col md={3}>
                    <Card className='TematicsTraining-card'>
                        <Card.Img src={ico2} className='TematicsTraining-icoImage' />
                        <Card.Body>
                            <Card.Title className='TematicsTraining-icoTitle'>Ambientación</Card.Title>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={3}>
                    <Card className='TematicsTraining-card'>
                        <Card.Img src={ico4} className='TematicsTraining-icoImage' />
                        <Card.Body>
                            <Card.Title className='TematicsTraining-icoTitle'>Maquinaria Inteligente</Card.Title>
                        </Card.Body>
                    </Card>  
                </Col>
                  
                <Col md={3}>
                    <Card className='TematicsTraining-card'>
                        <Card.Img src={ico1} className='TematicsTraining-icoImage' />
                        <Card.Body>
                            <Card.Title className='TematicsTraining-icoTitle'>Sensoramiento</Card.Title> 
                        </Card.Body>
                    </Card>
                </Col>

            </Row>
            
            <Row>
                <p className='Training-paragraph'>
                Dentro del Centro Experimental INIA Los Tilos tenemos un ecosistema de diferentes tecnologías que comprenden empresas que están desarrollando soluciones integradas al Smartfield INIA. Dentro de las capacitaciones se muestra de forma práctica la integración de ellas.
                </p>
                <br></br>
                <p className='Training-paragraph'>
                Pincha para ver las empresas involucradas.
                </p>
            </Row>
            
            <Row>
                <Tabs defaultActiveKey="tab1" id="tabEnterprise" fill success className='tab-custom'>
                    <Tab eventKey="tab1" title="RIEGO"> 
                        <Carousel>
                            <Carousel.Item>
                                <Row>
                                    <Col><img className="d-block w-100" src={AgroPrecision} alt="AgroPrecision" /></Col>
                                    <Col><img className="d-block w-100" src={DropControl} alt="DropControl" /></Col>
                                    <Col><img className="d-block w-100" src={Auravant} alt="Auravant" /></Col>
                                    <Col><img className="d-block w-100" src={GeoTrace} alt="GeoTrace" /></Col>
                                    <Col><img className="d-block w-100" src={Instacrops} alt="Instacrops" /></Col>
                                    <Col><img className="d-block w-100" src={Mapsens} alt="Mapsens" /></Col>
                                </Row>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Row>
                                    <Col><img className="d-block w-100" src={AquaDetect} alt="AquaDetect" /></Col>
                                    <Col><img className="d-block w-100" src={BlassTech} alt="BlassTech" /></Col>
                                    <Col><img className="d-block w-100" src={lemSystem} alt="lemSystem" /></Col>
                                    <Col><img className="d-block w-100" src={OneSoil} alt="OneSoil" /></Col>
                                    <Col><img className="d-block w-100" src={Olivos} alt="Olivos" /></Col>
                                </Row>
                            </Carousel.Item>
                        </Carousel>
                    </Tab>

                    <Tab eventKey="tab2" title="PLAGUICIDA" >
                        <Carousel>
                            <Carousel.Item>
                                <Row>
                                    <Col><img className="d-block w-100" src={Impac} alt="Impac" /></Col>
                                    <Col><img className="d-block w-100" src={Negume} alt="Negume" /></Col>
                                    <Col><img className="d-block w-100" src={TC} alt="TC" /></Col>
                                    <Col><img className="d-block w-100" src={INIA} alt="INIA" /></Col>
                                    <Col><img className="d-block w-100" src={Kimenda} alt="Kimenda" /></Col>
                                </Row>
                            </Carousel.Item>
                        </Carousel>
                    </Tab>

                    <Tab eventKey="tab3" title="FERTILIDAD" >
                        <Carousel>
                            <Carousel.Item>
                                <Row>
                                    <Col><img className="d-block w-100" src={Soval} alt="Soval" /></Col>
                                    <Col><img className="d-block w-100" src={Auravant} alt="Auravant" /></Col>
                                    <Col><img className="d-block w-100" src={OneSoil} alt="OneSoil" /></Col>
                                    <Col><img className="d-block w-100" src={INIA} alt="INIA" /></Col>
                                </Row>
                            </Carousel.Item>
                        </Carousel>
                    </Tab>

                    <Tab eventKey="tab4" title="BIG DATA" > 
                        <Carousel>
                            <Carousel.Item>
                                <Row>
                                    <Col><img className="d-block w-100" src={AgroPrecision} alt="AgroPrecision" /></Col>
                                    <Col><img className="d-block w-100" src={DropControl} alt="DropControl" /></Col>
                                    <Col><img className="d-block w-100" src={Auravant} alt="Auravant" /></Col>
                                    <Col><img className="d-block w-100" src={GeoTrace} alt="GeoTrace" /></Col>
                                    <Col><img className="d-block w-100" src={Instacrops} alt="Instacrops" /></Col>
                                    <Col><img className="d-block w-100" src={Mapsens} alt="Mapsens" /></Col>
                                </Row>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Row>
                                    <Col><img className="d-block w-100" src={AquaDetect} alt="AquaDetect" /></Col>
                                    <Col><img className="d-block w-100" src={BlassTech} alt="BlassTech" /></Col>
                                    <Col><img className="d-block w-100" src={lemSystem} alt="lemSystem" /></Col>
                                    <Col><img className="d-block w-100" src={OneSoil} alt="OneSoil" /></Col>
                                    <Col><img className="d-block w-100" src={Olivos} alt="Olivos" /></Col>
                                </Row>
                            </Carousel.Item>
                        </Carousel>
                    </Tab>
                </Tabs>
            </Row>
        </Container>
    );
};

export default TrainingTemactics;
