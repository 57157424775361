import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Banner.css';
import clip1 from './clip1.mov';

const Banner = () => {
    return (
        <div className="BannerContainer">
            <video className="BannerClip" src={clip1} autoPlay loop muted />
            <div className="BannerOverlay">
                <div className="BannerCard">
                    <h3 className="BannerTitle">Agroconecta</h3>
                    <p className="BannerParagraph">
                    Potenciando la resiliencia del sector agrícola frente a la pérdida de competitividad, a través de la capacitación y transferencia de tecnologías digitales. Iniciativa ﬁnanciado por la Corporación de Fomento de la Producción. 
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Banner;