import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Banner.css';
import clip1 from './clip2.mov';

const Banner = () => {
    return (
        <div className="BannerContainer">
            <video className="BannerClip" src={clip1} autoPlay loop muted />
            <div className="BannerOverlay">
                <div className="BannerCard">
                    <h3 className="BannerTitle">INDAP</h3>
                    <p className="BannerParagraph">
                    Nuestro objetivo general es implementar un sistema piloto de seguimiento y control hídrico, basado en las tecnologías de Monitoreo Satelital y Sensores de Humedad de Suelo, como herramienta de seguimiento y gestión del riego para grupos de usuarios SAT de INDAP, en las regiones de Valparaíso, Metropolitana, de O'Higgins y del Maule.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Banner;