import React, { useState } from 'react';
import { Modal, Button, Container, Row, Col, Card } from 'react-bootstrap';
import './Bookshelf.css'

// Importa más imágenes según sea necesario
import book1 from './assets/book1.png'
import book3 from './assets/book3.png'
import book2 from './assets/book2.png'
import book4 from './assets/book4.png'
import book5 from './assets/book5.png'
import book6 from './assets/book6.png'
import book7 from './assets/book7.png'
import book8 from './assets/book8.png'
import book9 from './assets/book9.png'
import book10 from './assets/book10.png'
import book11 from './assets/book11.png'
import book12 from './assets/book12.png'
import book13 from './assets/book13.png'
import book14 from './assets/book14.png'
import book15 from './assets/book15.png'
import book16 from './assets/book16.png'
import book17 from './assets/book17.png'
import book18 from './assets/book18.png'
import book19 from './assets/book19.png'
import book20 from './assets/book20.png'
import book21 from './assets/book21.png'
import book22 from './assets/book22.png'




const books = [
    {
        title: 'Introducción a la agricultura 4.0 ',
        author: 'Vargas Q., Paula; Best S., Stanley',
        institution: 'Instituto de Investigaciones Agropecuarias. Centro Regional de Investigación Quilamapu',
        serieDocumental: 'Serie 2',
        numberDocument: 'N°9 - Construir infraestructuras resilientes, promover la industrialización inclusiva y sotenible y fomentar la innovación',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/68862/NR43084.pdf?sequence=1&isAllowed=y',
        image: book1
    },
    {
        title: 'Robótica: La solución a los efectos del cambio climático en los cultivos',
        author: 'Best S., Stanley',
        institution: '',
        serieDocumental: 'Revista Campo & Tecnología',
        numberDocument: 'N° Documento: (no. 6) p. 26-27',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/68085/NR42617.pdf?sequence=1&isAllowed=y',
        image: book2
    },
    {
        title: 'Visión actual y futura de la tecnología en la agricultura',
        author: 'Vargas Q., Paula; Best S., Stanley',
        institution: '',
        serieDocumental: 'Revista Campo & Tecnología',
        numberDocument: '(no. 1) p. 25-27',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/68073/NR42606.pdf?sequence=1&isAllowed=y',
        image: book3
    },
    {
        title: 'Adopción y desarrollo de tecnología en agricultura de precisión ',
        author: 'Best S., Stanley; León G., Lorenzo; Méndez, Andrés; Flores, Fabiola; Aguilera C., Hernán',
        institution: 'Instituto de Investigaciones Agropecuarias;',
        serieDocumental: '',
        numberDocument: 'N°9: Construir infraestructuras resilientes, promover la industrialización inclusiva y sotenible y fomentar la innovación',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/68414/NR42755.pdf?sequence=1&isAllowed=y',
        image: book4
    },
    {
        title: 'Viticultura de precisión: Terroir Digital ',
        author: 'Best S., Stanley; León G., Lorenzo; Quintana L., Rodrigo; Aguilera C., Hernán; Flores P., Fabiola; Concha C., Valeska',
        institution: 'Instituto de Investigaciones Agropecuarias. Centro Regional de Investigación Quilamapu',
        serieDocumental: 'Boletín INIA - Instituto de Investigaciones Agropecuarias',
        numberDocument: 'N° Publicación: no. 269',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/7659/Boletín%20INIA%20N°%20269?sequence=1&isAllowed=y',
        image: book5
    },
    {
        title: 'Olivicultura de precisión: nuevas tecnologías aplicables a olivicultura ',
        author: 'Best S., Stanley; León G., Lorenzo; Quintana L., Rodrigo; Flores P., Fabiola; Aguilera C., Hernán; Concha C., Valeska; Thomas, Pablo',
        institution: 'Instituto de Investigaciones Agropecuarias. Centro Regional de Investigación Quilamapu',
        serieDocumental: 'Boletín INIA - Instituto de Investigaciones Agropecuarias',
        numberDocument: 'N° Publicación: no. 253',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/7579/NR38783.pdf?sequence=6&isAllowed=y',
        image: book6
    },
    {
        title: 'Handbook de agricultura de precisión ',
        author: 'Best S., Stanley',
        institution: 'Instituto de Investigaciones Agropecuarias',
        serieDocumental: '',
        numberDocument: 'N°9: Construir infraestructuras resilientes, promover la industrialización inclusiva y sotenible y fomentar la innovación',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/68410/NR42751.pdf?sequence=1&isAllowed=y',
        image: book7
    },
    {
        title: 'Elementos de vitivinicultura de precisión ',
        author: 'Best S., Stanley; León G., Lorenzo (eds.)',
        institution: 'Instituto de Investigaciones Agropecuarias. Centro Regional de Investigación Quilamapu',
        serieDocumental: 'Boletín INIA - Instituto de Investigaciones Agropecuarias',
        numberDocument: 'N° Publicación: no. 136',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/7120/NR34380.pdf?sequence=6&isAllowed=y',
        image: book8
    },
    {
        title: 'Agricultura de precisión: integrando conocimientos para una agricultura moderna y sustentable ',
        author: 'Bongiovanni, Rodolfo G; Montovani C., Evandro; Best S., Stanley; Roel, Alvaro',
        institution: 'Instituto Interamericano de Cooperación para la Agricultura (IICA);',
        serieDocumental: '',
        numberDocument: 'N°9: Construir infraestructuras resilientes, promover la industrialización inclusiva y sotenible y fomentar la innovación',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/68412/NR42753.pdf?sequence=1&isAllowed=y',
        image: book9
    },
    {
        title: 'Medición de propiedades del suelo utilizando un sensor de conductividad eléctrica: Vitivinicultura de precisión ',
        author: 'Flores M., Luis; Best S., Stanley',
        institution: 'Instituto de Investigaciones Agropecuarias. Centro Regional de Investigación Raihuén',
        serieDocumental: 'Informativo INIA Raihuén',
        numberDocument: 'N° Publicación: no. 11',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/4119/Informativo%20INIA%20N°%2011?sequence=1&isAllowed=y',
        image: book10
    },
    {
        title: 'Agricultura de precisión: la tecnología del nuevo milenio ',
        author: 'Best S., Stanley',
        institution: '',
        serieDocumental: 'Tierra Adentro',
        numberDocument: 'N° Publicación: no. 85',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/5087/NR36247.pdf?sequence=1&isAllowed=y',
        image: book11
    },
    {
        title: 'Aplicación de la agricultura tecnológica 4.0 ',
        author: 'Best S., Stanley; Vargas Q., Paula',
        institution: 'Instituto de Investigaciones Agropecuarias. Centro Regional de Investigación Quilamapu',
        serieDocumental: 'Informativo INIA Quilamapu',
        numberDocument: 'N° Publicación: no. 148',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/4011/Informativo%20INIA%20N°%20148?sequence=1&isAllowed=y',
        image: book12
    },
    {
        title: 'Viticultura de precisión. Producción de vinos de alta calidad ',
        author: 'Best S., Stanley; León G., Lorenzo',
        institution: '',
        serieDocumental: 'Tierra Adentro',
        numberDocument: 'N° Publicación: no. 61',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/6155/NR32178.pdf?sequence=1&isAllowed=y',
        image: book13
    },
    {
        title: 'Soluciones prácticas a problemas frecuentes. Manejo de pivotes centrales ',
        author: 'Best S., Stanley',
        institution: '',
        serieDocumental: 'Tierra Adentro',
        numberDocument: 'N° Publicación: no. 46',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/5952/NR28619.pdf?sequence=1&isAllowed=y',
        image: book14
    },
    {
        title: 'Agricultura de precisión: herramienta para mejorar la rentabilidad en trigo ',
        author: 'Claret M., Marcelino; Best S., Stanley; León G., Lorenzo; Santana U., Gustavo',
        institution: '',
        serieDocumental: 'Tierra Adentro',
        numberDocument: 'N° Publicación: no. 78',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/6361/NR34742.pdf?sequence=1&isAllowed=y',
        image: book15
    },
    {
        title: 'Aplicación de agricultura de precisión en Chile: nuevos desafíos en cultivos tradicionales ',
        author: 'León G., Lorenzo; Best S., Stanley',
        institution: '',
        serieDocumental: 'Informativo Agropecuario Bioleche INIA Quilamapu',
        numberDocument: 'N° Documento: v. 20(4) p. 12-15',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/41402/Articulo?sequence=1&isAllowed=y',
        image: book16
    },
    {
        title: 'Necesidades nutricionales del cultivo de maíz en el valle regado de la VIII región ',
        author: 'Hirzel C., Juan; Best S., Stanley',
        institution: '',
        serieDocumental: 'Informativo Agropecuario Bioleche INIA Quilamapu',
        numberDocument: 'N° Documento: v. 15(3) p. 49-51',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/41340/Articulo?sequence=1&isAllowed=y',
        image: book17
    },
    {
        title: 'Aplicación de químicos mediante pivote central ',
        author: 'Best S., Stanley',
        institution: '',
        serieDocumental: 'Informativo Agropecuario Bioleche INIA Quilamapu',
        numberDocument: 'v. 14(3) p. 36-40',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/34451/Articulo?sequence=1&isAllowed=y',
        image: book18
    },
    {
        title: 'Agricultura de precisión en cebada cervecera ',
        author: 'León G., Lorenzo; Best S., Stanley; Aliaga I., Claudio; Cortez M., Ricardo',
        institution: '',
        serieDocumental: 'Informativo Agropecuario Bioleche INIA Quilamapu',
        numberDocument: 'N° Documento: v. 22(4) p. 43-45',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/41918/NR37161.pdf?sequence=1&isAllowed=y',
        image: book19
    },
    {
        title: 'Agricultura de precisión. Una plataforma para la fruticultura de exportación (3a parte) ',
        author: 'Best S., Stanley; Claret M., Marcelino',
        institution: '',
        serieDocumental: 'Tierra Adentro',
        numberDocument: 'N° Publicación: no. 61',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/6156/NR32179.pdf?sequence=1&isAllowed=y',
        image: book20
    },
    {
        title: 'Cuantificación de la lixiviación de nitratos en trigo regado con diferentes estrategias de Fertilización nitrogenada en un Alfisol',
        author: 'Claret M., Marcelino; Urrutia P., Roberto; Ortega B., Rodrigo; Best S., Stanley; Valderrama V., Natalia',
        institution: '',
        serieDocumental: 'Chilean Journal of Agricultural Research',
        numberDocument: 'N° Documento: v. 71(1) p. 148-156',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/40542/Articulo?sequence=1&isAllowed=y',
        image: book21
    },
    {
        title: 'La agricultura de precisión. Fundamentos, aplicaciones y equipos(1a parte) ',
        author: 'Best S., Stanley; Claret M., Marcelino',
        institution: '',
        serieDocumental: 'Tierra Adentro',
        numberDocument: 'N° Publicación: no. 61',
        buttonReference: 'https://biblioteca.inia.cl/bitstream/handle/20.500.14001/6154/NR32177.pdf?sequence=1&isAllowed=y',
        image: book22
    }
];

const BookShelf = () => {
    const [show, setShow] = useState(false);
    const [selectedBook, setSelectedBook] = useState(null);

    const handleShow = (book) => {
        setSelectedBook(book);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setSelectedBook(null);
    };

    return (
        <Container className='containerlibrary-bookshelft'>
            <Row>
                <h2 className='bookshelf-title'>Libreria</h2>
            </Row>
            <Row>
                {books.map((book, index) => (
                    <Col key={index} md={3}>
                        <Card onClick={() => handleShow(book)} className="mb-3" style={{ cursor: 'pointer' }}>
                            <Card.Img variant="top" src={book.image} />
                            <Card.Body>
                                <Card.Title>{book.title}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>

            <Modal show={show} onHide={handleClose}>
                {selectedBook && (
                    <BookDetails book={selectedBook} handleClose={handleClose} />
                )}
            </Modal>
        </Container>
    );
};

const BookDetails = ({ book, handleClose }) => {
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{book.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col md={4}>
                            <img src={book.image} alt={book.title} className="img-fluid" />
                        </Col>
                        <Col md={8}>
                            <p><strong>Autor:</strong> {book.author}</p>
                            <p><strong>Institución:</strong> {book.institution}</p>
                            <p><strong>Serie Documental:</strong> {book.serieDocumental}</p>
                            <p><strong>N° Documento:</strong> {book.numberDocument}</p>
                            <Button href={book.buttonReference} target="_blank" variant="primary">Más información</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </>
    );
};

export default BookShelf;
