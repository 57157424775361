import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Us from './pages/Us';
import Smartfield from './pages/Smartfield';
import INDAP from './pages/INDAP';
import Agroconecta from './pages/Agroconecta';
import Campus from './pages/Campus';
import NavigationBar from './components/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import Footer from './components/Footer';
import Events from './pages/Events';
import Library from './pages/Library';

const App = () => {
   return (

      //Rutas de Navegacion
      
       <Router>
           <NavigationBar />
           <Switch>
               <Route exact path="/" component={Home} />
               <Route path="/us" component={Us} />
               <Route path="/smartfield" component={Smartfield} />
               <Route path="/indap" component={INDAP} />
               <Route path="/agroconecta" component={Agroconecta} />
               <Route path="/campus" component={Campus} />
               <Route path="/events" component={Events}/>
               <Route path="/library" component={Library}/>
           </Switch>
           <Footer />
       </Router>
   );
};

export default App;
